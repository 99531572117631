import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FeaturesSection.css';

const FeaturesSection = () => {
  return (
    <Container id="features" className="text-center my-5">
      <h2>Features</h2>
      <hr className="feature-divider" />
      <Row className="mt-4">
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-shield-alt fa-3x" style={{ color: '#4caf50' }}></i>
          </div>
          <h4>Real-time Weapon Detection</h4>
          <p>Uses AI-powered object detection to identify handguns and other firearms in public spaces instantly, ensuring rapid response.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-bullseye fa-3x" style={{ color: '#9c27b0' }}></i>
          </div>
          <h4>Shooter Deterrent</h4>
          <p>Deploys multiple lassos or tethering devices to physically immobilize the shooter, preventing further actions.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-bell fa-3x" style={{ color: '#2196f3' }}></i>
          </div>
          <h4>Emergency Alert System</h4>
          <p>Immediately notifies nearby emergency services and law enforcement with exact location details, speeding up response time.</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-video fa-3x" style={{ color: '#f44336' }}></i>
          </div>
          <h4>Live Monitoring and Alerts</h4>
          <p>Provides a live video feed to authorized personnel, allowing for constant monitoring and enabling fast decision-making in critical situations.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-chart-line fa-3x" style={{ color: '#ff5722' }}></i>
          </div>
          <h4>Advanced Threat Assessment</h4>
          <p>Identifies and categorizes potential threats based on weapon type and user behavior, giving a complete risk analysis.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-headset fa-3x" style={{ color: '#8bc34a' }}></i>
          </div>
          <h4>Crowd Protection Protocol</h4>
          <p>Issues audio-visual alerts to notify nearby people, directing them to safe exits, minimizing panic, and aiding crowd management.</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-cloud fa-3x" style={{ color: '#3f51b5' }}></i>
          </div>
          <h4>Comprehensive Data Logging</h4>
          <p>Records all incidents, detections, and responses for future training, legal use, and system improvement, supporting transparency and accountability.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-robot fa-3x" style={{ color: '#e91e63' }}></i>
          </div>
          <h4>Adaptive AI Model</h4>
          <p>Continuously learns and adapts to detect concealed weapons or those in unconventional scenarios, increasing accuracy in diverse environments.</p>
        </Col>
        <Col md={4} className="mb-4 feature-item">
          <div className="feature-icon">
            <i className="fas fa-times-circle fa-3x" style={{ color: '#00bcd4' }}></i>
          </div>
          <h4>Remote System Control</h4>
          <p>Allows security personnel to remotely engage or disengage features, making the system highly flexible and responsive to dynamic situations.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturesSection;
