// import React from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import './ContactUsSection.css';

// const ContactUsSection = () => {
//   return (
//     <div id="contactus" className="contact-section">
//       <Container className="my-5">
//         <Row>
//           <Col md={6} className="contact-form">
//             <h2 className="contact-title">Get in Touch</h2>
//             <hr className="contact-divider" />
//             <p
//                 className="contact-description"
//                 style={{color: 'white'}}
//             >
//               Please fill out the form below to send us an email and we will get back to you as soon as possible.
//             </p>
//             <Form>
//               <Form.Group controlId="formName" className="mb-3">
//                 <Form.Control type="text" placeholder="Name"/>
//               </Form.Group>
//               <Form.Group controlId="formEmail" className="mb-3">
//                 <Form.Control type="email" placeholder="Email" />
//               </Form.Group>
//               <Form.Group controlId="formMessage" className="mb-3">
//                 <Form.Control as="textarea" rows={5} placeholder="Message" />
//               </Form.Group>
//               <Button variant="outline-light" type="submit" className="send-message-button">
//                 Send Message
//               </Button>
//             </Form>
//           </Col>
//           <br></br>
//           <br></br>
//           <Col md={6} className="contact-info" style={{ marginTop: '2rem' }}>
//             <h3>Contact Info</h3>
//             <ul className="info-list">
//               <li><i className="fas fa-phone"></i> Phone: <a href="tel:+1480-263-9999" style={{ color: 'white', textDecoration: 'none' }}>+1 480-263-9999</a></li>
//               <li><i className="fas fa-envelope"></i> Email: <a href="mailto:info@sky-strike.com" style={{ color: 'white', textDecoration: 'none' }}> info@sky-strike.com</a></li>
//             </ul>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default ContactUsSection;

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactUsSection.css';

const ContactUsSection = () => {
  // State to store form input values
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handler to update state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handler to manage form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('http://localhost:5000/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        alert('Message sent successfully!');
      } else {
        alert('Failed to send message, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send message, please try again later.');
    }
  };

  return (
    <div id="contactus" className="contact-section">
      <Container className="my-5">
        <Row>
          <Col md={6} className="contact-form">
            <h2 className="contact-title">Get in Touch</h2>
            <hr className="contact-divider" />
            <p
                className="contact-description"
                style={{color: 'white'}}
            >
              Please fill out the form below to send us an email and we will get back to you as soon as possible.
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button variant="outline-light" type="submit" className="send-message-button">
                Send Message
              </Button>
            </Form>
          </Col>
          <br></br>
          <br></br>
          <Col md={6} className="contact-info" style={{ marginTop: '2rem' }}>
            <h3>Contact Info</h3>
            <ul className="info-list">
              <li><i className="fas fa-phone"></i> Phone: <a href="tel:+1480-263-9999" style={{ color: 'white', textDecoration: 'none' }}>+1 480-263-9999</a></li>
              <li><i className="fas fa-envelope"></i> Email: <a href="mailto:info@sky-strike.com" style={{ color: 'white', textDecoration: 'none' }}> info@sky-strike.com</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsSection;
