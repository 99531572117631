import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUsSection.css';

const AboutUsSection = () => {
  return (
      <Container id="about" className="my-5">
          <h2 className="about-title">About Us</h2>
          <hr className="about-divider" style={{ marginTop: '0.5rem' }} />
          <p className="about-description">
              Sky Strike is an innovative technology company dedicated to enhancing public safety through cutting-edge AI solutions. With a focus on proactive threat detection and prevention, we develop advanced systems designed to safeguard communities and empower emergency services in crisis situations. Our mission is to create a safer world through intelligent technology and responsive design.
          </p>
          <h3 className="why-choose-us-title">Why Choose Us?</h3>
          <Row className="mt-4">
              <Col md={6}>
                  <ul className="about-list">
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Proactive Public Safety Innovation
                      </li>
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Integrated Emergency Response System
                      </li>
                  </ul>
              </Col>
              <Col md={6}>
                  <ul className="about-list">
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Adaptable to Dynamic Scenarios
                      </li>
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Ethical and Non-Lethal Approach
                      </li>
                  </ul>
              </Col>
          </Row>
      </Container>

  );
};

export default AboutUsSection;