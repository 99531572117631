import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer text-center py-4">
      <Container>
        <p>©2024 Sky-Strike. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
