import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../file.png'; // Make sure to adjust the path to your logo image

const NavbarComponent = () => {
    return (
      <Navbar bg="light" expand="lg" style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <Container>
          <Navbar.Brand href="#" style={{ fontWeight: 'bold', fontSize: '1.7rem', display: 'flex', alignItems: 'center', gap: '0.5rem', marginRight: '6rem', color:'#156669' }}>
            <img
              src={logo}
              alt="Logo"
              width="70"
              height="70"
              className="d-inline-block align-top"
            />
            Sky-Strike
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto" style={{ gap: '3rem', fontSize: '1.1rem', fontWeight: '500' }}>
              <Nav.Link href="#features" >Features</Nav.Link>
              <Nav.Link href="#about" >About</Nav.Link>
              {/* <Nav.Link href="#services" >Services</Nav.Link> */}
              {/* <Nav.Link href="#gallery" >Gallery</Nav.Link> */}
              {/* <Nav.Link href="#testimonials" >Testimonials</Nav.Link> */}
              {/* <Nav.Link href="#team" >Team</Nav.Link> */}
              <Nav.Link href="#contactus" >Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };
  
export default NavbarComponent;
