// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import React from 'react';
import NavbarComponent from './components/Navbar';
import HeroSection from './components/Header';
import FeaturesSection from './components/Features';
import About from './components/About';
import Footer from './components/Footer';
import ContactUsSection from './components/Contact';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <>
      <NavbarComponent />
      <HeroSection />
      <Analytics />
      <SpeedInsights />
      <FeaturesSection />
      <About />
      <ContactUsSection />
      <Footer />
    </>
  );
}

export default App;


